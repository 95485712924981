@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Oswald', sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Oswald:wght@700&display=swap");
}

.btn-secondary {
  background-color: transparent !important;
  border: none !important;
}

/* Animation for scrolling text */
.highlighted-message-long {
  display: inline-block;
  white-space: nowrap;
  animation: floatText 9s infinite linear;
  padding-left: 100%; /*Initial offset*/
}
.highlighted-message-long:hover {
  animation-play-state: paused;
}
@keyframes floatText {
  to {
    transform: translateX(-100%);
  }
}

/* Spinner animation */
.spinnerImage {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinnerContainer {
  margin: auto;
}

/* Styles for parallax */
.parallax {
  background-image: url("../../images/Parallax.jpeg");
  height: 500px; /* Set a specific height */
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .parallax {
    background-image: url("../../images/Parallaxmobile.jpeg"); /* different image for mobile parallax */
    background-attachment: scroll; /* removing parallax effect because it fails in some mobile devices*/
  }
}

/* Avoids overlapping with header */
/* TODO: improve approach, aim for a more generic solution */
body {
  padding-top: 77px;
}
/* @media screen and (max-width: 768px) {
  body {
    padding-top: 85px;
  }
} */

/* Styles for active nav element */
.nav-link.active {
  border-bottom: 1px solid white;
  font-weight: 600;
}
.navbar-nav .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-item .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#header-cart-icon {
padding-top: 1rem !important;
padding-bottom: 1rem !important;
}

@media screen and (max-width: 768px) {
  #header-cart-icon {
    padding-left: 2.5rem !important;
  }
}

.nav-item {
  margin-left: 1rem;
  margin-right: 1rem;
}

#navbarSupportedContent {
  margin-right: 4rem;
}

/* .container-fluid {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
} */

.flex-row {
  display: flex !important;
  justify-content: space-around !important;
  padding-left: 3rem !important;
}

/* Styles for product columns that expand on hover */
.product-expansible-col {
  flex: 1;
  transition: all 0.5s ease-in-out;
  height: 800px;
}
.product-expansible-col:hover {
  flex: 1.8;
}
.product-expansible-text {
  visibility: hidden;
}
.product-expansible-text:hover + .opacity-layer {
  /* Changes opacity layer based on text element hover*/
  opacity: 0.6;
}
.product-expansible-col:hover .product-expansible-text {
  transition: all 0.5s step-end;
  visibility: visible;
}
.opacity-layer {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.opacity-layer:hover {
  opacity: 0.6;
}

/* styles for contact form */
.ant-input {
  border-radius: 30px;
  border-color: grey;
  padding: 10px;
  padding-left: 15px;
}
.ant-btn.ant-btn-primary.ant-btn-lg {
  background-color: black;
  width: 40%;
}
.ant-btn.ant-btn-primary.ant-btn-lg:disabled {
  background-color: grey;
  color: lightgray;
  width: 40%;
}

/* Styles for Pure React Carousel */
.buttonBack,
.buttonBack:focus {
  position: absolute;
  top: 50%;
  left: -1.5rem;
  /* transform: translateY(-10vh); */
  background-color: transparent;
  border: none;
  outline: none;
}

.buttonNext,
.buttonNext:focus {
  color: transparent;
  position: absolute;
  top: 50%;
  right: -1.5rem;
  /* transform: translateY(-10vh); */
  background-color: transparent;
  border: none;
  outline: none;
  transform: rotate(180deg);
}

.carousel__dot-group {
  position: relative;
  top: -60px;
}
.carousel__dot {
  background-color: white !important;
  opacity: 0.75;
  border: none;
  padding: 0;
  margin: 4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  top: -20px;
}
.carousel__dot--selected {
  background-color: rgb(227, 126, 140) !important;
  opacity: 0.75;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}


/* headerWithSidelines styles */
.headerWithSidelines{
  overflow: hidden;
  text-align: center;
}
.headerWithSidelines > span{
 position: relative;
 display: inline-block;
}
.headerWithSidelines > span:before, .headerWithSidelines > span:after{
 content: '';
 position: absolute;
 top: 50%;
 border-bottom: 2px solid #cecece;
 width: 100vw;
 margin: 0 20px;
}
.headerWithSidelines > span:before{
 right: 100%;
}
.headerWithSidelines > span:after{
 left: 100%;
}

/* Hoverable category layer style */
.hoverable-category-layer {
  font-family: "Oswald";
  overflow: hidden;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef1f1;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 110%;
}
.hoverable-category-layer:hover {
  opacity: 0.75;
  filter: alpha(opacity=75);
}

/* Hoverable button default styles */
.hoverable-button {
  color: black;
  background-color: white;
  border-radius: 12px;
  font-weight: 700;
}
.hoverable-button:hover {
  color: white;
  background-color: black;
}
.hoverable-button:focus {
  color: black;
}
.hoverable-button:focus:hover {
  color: white;
}
.hoverable-button:active {
  box-shadow: 0px 0px 10px darkgray !important;
  color: darkgray !important;
  transform: translateY(4px);
  transition: box-shadow 0s;
}

/* Modal styles */
.modal-header .btn-close {
  padding: 0 .5rem !important;
}
.modal-header {
  justify-content: center !important;
}