@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Oswald:wght@700&display=swap);
* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* Overriding Ant Design styles */
.ant-card-head,
.ant-card-head-title {
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.ant-card-body {
  padding: 4px 0 0 0 !important;
}

.ant-card {
  margin: 5px 10px !important;
}

.ant-btn {
  border: 0 !important;
}

/* Styles for amount of items in cart */
.badge {
  height: 100;
  width: 100;
  border-radius: 50% !important;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

#lblCartCount {
  font-size: 12px;
  color: #fff;
  padding: 5px;
  vertical-align: top;
  margin-left: -10px;
}

/* Navbar fixed on top */
.fixed-top {
  position: fixed !important;
}

/* Remove border from navbar toggler, improve vertical alignment */
.navbar-toggler {
  border: none !important;
  padding: 1rem 0.75rem !important;
}

/* Overlay for mobile navbar */
.navbar-overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 1);
  overflow-y: hidden;
  transition: 0.5s;
}

.navbar-overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: left;
  /* margin-top: 30px; */
  padding-left: 15%;
  list-style-type: none;
}

.navbar-overlay a {
  padding: 8px;
  text-decoration: none;
  /* font-size: 36px; */
  /* color: #818181; */
  display: block;
  transition: 0.3s;
}

.navbar-overlay a:hover,
.navbar-overlay a:focus {
  color: #f1f1f1;
}

.navbar-overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* Styles for amount of items in cart */
.badge {
  height: 100;
  width: 100;
  border-radius: 50% !important;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

#lblCartCount {
  font-size: 12px;
  color: #fff;
  padding: 5px;
  vertical-align: top;
  margin-left: -10px;
}

@media screen and (max-height: 450px) {
  .navbar-overlay {
    overflow-y: auto;
  }
  .navbar-overlay a {
    font-size: 20px;
  }
  .navbar-overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Oswald', sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Oswald:wght@700&display=swap");
}

.btn-secondary {
  background-color: transparent !important;
  border: none !important;
}

/* Animation for scrolling text */
.highlighted-message-long {
  display: inline-block;
  white-space: nowrap;
  -webkit-animation: floatText 9s infinite linear;
          animation: floatText 9s infinite linear;
  padding-left: 100%; /*Initial offset*/
}
.highlighted-message-long:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@-webkit-keyframes floatText {
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@keyframes floatText {
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

/* Spinner animation */
.spinnerImage {
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinnerContainer {
  margin: auto;
}

/* Styles for parallax */
.parallax {
  background-image: url(/static/media/Parallax.00712223.jpeg);
  height: 500px; /* Set a specific height */
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .parallax {
    background-image: url(/static/media/Parallaxmobile.8164fe6b.jpeg); /* different image for mobile parallax */
    background-attachment: scroll; /* removing parallax effect because it fails in some mobile devices*/
  }
}

/* Avoids overlapping with header */
/* TODO: improve approach, aim for a more generic solution */
body {
  padding-top: 77px;
}
/* @media screen and (max-width: 768px) {
  body {
    padding-top: 85px;
  }
} */

/* Styles for active nav element */
.nav-link.active {
  border-bottom: 1px solid white;
  font-weight: 600;
}
.navbar-nav .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-item .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#header-cart-icon {
padding-top: 1rem !important;
padding-bottom: 1rem !important;
}

@media screen and (max-width: 768px) {
  #header-cart-icon {
    padding-left: 2.5rem !important;
  }
}

.nav-item {
  margin-left: 1rem;
  margin-right: 1rem;
}

#navbarSupportedContent {
  margin-right: 4rem;
}

/* .container-fluid {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
} */

.flex-row {
  display: flex !important;
  justify-content: space-around !important;
  padding-left: 3rem !important;
}

/* Styles for product columns that expand on hover */
.product-expansible-col {
  flex: 1 1;
  transition: all 0.5s ease-in-out;
  height: 800px;
}
.product-expansible-col:hover {
  flex: 1.8 1;
}
.product-expansible-text {
  visibility: hidden;
}
.product-expansible-text:hover + .opacity-layer {
  /* Changes opacity layer based on text element hover*/
  opacity: 0.6;
}
.product-expansible-col:hover .product-expansible-text {
  transition: all 0.5s step-end;
  visibility: visible;
}
.opacity-layer {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.opacity-layer:hover {
  opacity: 0.6;
}

/* styles for contact form */
.ant-input {
  border-radius: 30px;
  border-color: grey;
  padding: 10px;
  padding-left: 15px;
}
.ant-btn.ant-btn-primary.ant-btn-lg {
  background-color: black;
  width: 40%;
}
.ant-btn.ant-btn-primary.ant-btn-lg:disabled {
  background-color: grey;
  color: lightgray;
  width: 40%;
}

/* Styles for Pure React Carousel */
.buttonBack,
.buttonBack:focus {
  position: absolute;
  top: 50%;
  left: -1.5rem;
  /* transform: translateY(-10vh); */
  background-color: transparent;
  border: none;
  outline: none;
}

.buttonNext,
.buttonNext:focus {
  color: transparent;
  position: absolute;
  top: 50%;
  right: -1.5rem;
  /* transform: translateY(-10vh); */
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.carousel__dot-group {
  position: relative;
  top: -60px;
}
.carousel__dot {
  background-color: white !important;
  opacity: 0.75;
  border: none;
  padding: 0;
  margin: 4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  top: -20px;
}
.carousel__dot--selected {
  background-color: rgb(227, 126, 140) !important;
  opacity: 0.75;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}


/* headerWithSidelines styles */
.headerWithSidelines{
  overflow: hidden;
  text-align: center;
}
.headerWithSidelines > span{
 position: relative;
 display: inline-block;
}
.headerWithSidelines > span:before, .headerWithSidelines > span:after{
 content: '';
 position: absolute;
 top: 50%;
 border-bottom: 2px solid #cecece;
 width: 100vw;
 margin: 0 20px;
}
.headerWithSidelines > span:before{
 right: 100%;
}
.headerWithSidelines > span:after{
 left: 100%;
}

/* Hoverable category layer style */
.hoverable-category-layer {
  font-family: "Oswald";
  overflow: hidden;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef1f1;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 110%;
}
.hoverable-category-layer:hover {
  opacity: 0.75;
  filter: alpha(opacity=75);
}

/* Hoverable button default styles */
.hoverable-button {
  color: black;
  background-color: white;
  border-radius: 12px;
  font-weight: 700;
}
.hoverable-button:hover {
  color: white;
  background-color: black;
}
.hoverable-button:focus {
  color: black;
}
.hoverable-button:focus:hover {
  color: white;
}
.hoverable-button:active {
  box-shadow: 0px 0px 10px darkgray !important;
  color: darkgray !important;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition: box-shadow 0s;
}

/* Modal styles */
.modal-header .btn-close {
  padding: 0 .5rem !important;
}
.modal-header {
  justify-content: center !important;
}
