@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Oswald:wght@700&display=swap');
* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* Overriding Ant Design styles */
.ant-card-head,
.ant-card-head-title {
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.ant-card-body {
  padding: 4px 0 0 0 !important;
}

.ant-card {
  margin: 5px 10px !important;
}

.ant-btn {
  border: 0 !important;
}

/* Styles for amount of items in cart */
.badge {
  height: 100;
  width: 100;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50% !important;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

#lblCartCount {
  font-size: 12px;
  color: #fff;
  padding: 5px;
  vertical-align: top;
  margin-left: -10px;
}

/* Navbar fixed on top */
.fixed-top {
  position: fixed !important;
}

/* Remove border from navbar toggler, improve vertical alignment */
.navbar-toggler {
  border: none !important;
  padding: 1rem 0.75rem !important;
}

/* Overlay for mobile navbar */
.navbar-overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 1);
  overflow-y: hidden;
  transition: 0.5s;
}

.navbar-overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: left;
  /* margin-top: 30px; */
  padding-left: 15%;
  list-style-type: none;
}

.navbar-overlay a {
  padding: 8px;
  text-decoration: none;
  /* font-size: 36px; */
  /* color: #818181; */
  display: block;
  transition: 0.3s;
}

.navbar-overlay a:hover,
.navbar-overlay a:focus {
  color: #f1f1f1;
}

.navbar-overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* Styles for amount of items in cart */
.badge {
  height: 100;
  width: 100;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50% !important;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

#lblCartCount {
  font-size: 12px;
  color: #fff;
  padding: 5px;
  vertical-align: top;
  margin-left: -10px;
}

@media screen and (max-height: 450px) {
  .navbar-overlay {
    overflow-y: auto;
  }
  .navbar-overlay a {
    font-size: 20px;
  }
  .navbar-overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
